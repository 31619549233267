// Header
.header
  position: relative
  width: 100%
  margin-left: auto
  margin-right: auto
  color: $white
  padding-top: 16px
  padding-left: 16px
  padding-right: 16px

  display: flex
  flex-direction: column
  justify-content: center

  +media-breakpoint-up(sm)
    padding-top: 24px
    padding-left: 24px
    padding-right: 24px

  +media-breakpoint-up(md)
    padding-top: 40px
    padding-left: 48px
    padding-right: 48px

  +media-breakpoint-up(lg)
    padding-top: 32px
    padding-left: 0
    padding-right: 0

  +container-width(lg)
  +container-width(xl)
  +container-width(xxl)
    padding-top: 40px

  &__curtain
    position: absolute
    top: 0
    left: 0
    right: 0
    height: 8px
    width: 100vw
    margin: 0 auto
    background: $black
    transition: height 600ms cubic-bezier(0.76, 0, 0.24, 1)
    z-index: 900

    &-wrapper
      display: flex
      flex-direction: column
      justify-content: space-between
      height: 100%
      padding: 24px
      opacity: 0
      transition: opacity 300ms ease-in-out

      +media-breakpoint-up(md)
        display: none

    +media-breakpoint-up(sm)
      height: 10px

    +media-breakpoint-up(md)
      height: 20px

    +media-breakpoint-up(lg)
      height: 14px

    +media-breakpoint-up(xl)
      height: 17px

    +media-breakpoint-up(xxl)
      height: 20px

    +container-width(lg)
    +container-width(xl)
    +container-width(xxl)

    &--opened

      .header__curtain-wrapper
        opacity: 1

      +media-breakpoint-down(md)
        height: 50vh

  &__wrapper
    display: flex
    align-items: center
    justify-content: space-between

// Local time
.local-time
  font-family: $font-family-sans-serif-book
  font-size: 13px

  +media-breakpoint-up(xl)
    font-size: 14px

  +media-breakpoint-up(xxl)
    font-size: 16px

.company-info
  display: none

  &--under-curtain

    +media-breakpoint-down(md)
      display: flex
      flex-direction: column

      & .company-info__location
        padding-bottom: 12px


  +media-breakpoint-up(md)
    display: block

  &__location
    margin-right: 30px
    font-family: $font-family-sans-serif-book
    font-size: 13px

    +media-breakpoint-up(xl)
      font-size: 14px

    +media-breakpoint-up(xxl)
      font-size: 16px

  &__email
    display: inline-flex
    position: relative
    overflow: hidden
    padding-bottom: 8px

    color: $white
    text-decoration: none
    font-family: $font-family-sans-serif-medium
    font-size: 13px

    &:hover
      &::after
        transform: translate3d(0, 0, 0)

    &::after
      content: ""
      width: 100%
      height: 1px
      background-color: currentcolor
      position: absolute
      left: 0
      bottom: 0

      transform: translate3d(-105%, 0, 0)
      will-change: transform
      transition: transform .2s 0s ease-in

    +media-breakpoint-up(xl)
      font-size: 14px

    +media-breakpoint-up(xxl)
      font-size: 16px

// Logo
.logo
  width: 80px

  &__svg

  +media-breakpoint-up(sm)
    width: 97px

  +media-breakpoint-up(xl)
    width: 110px

  +media-breakpoint-up(xxl)
    width: 133px

// Language switcher
.lang-switcher
  width: 62px
  position: relative
  display: none
  flex-flow: row nowrap
  justify-content: space-between
  color: $black

  &--under-curtain
    color: $gray

    +media-breakpoint-down(md)
      display: flex

  +media-breakpoint-up(md)
    display: flex

  &__divider
    position: absolute
    height: 1px
    background: $white
    width: 16px
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

  &__entry
    margin-right: 12px
    position: relative
    text-transform: uppercase
    text-decoration: none
    font-family: $font-family-sans-serif-regular
    font-size: 12px
    cursor: pointer
    transition: color 400ms ease

    &:hover
      color: $white

    &:last-child
      margin-right: 0
      margin-left: 12px

    +media-breakpoint-up(xl)
      font-size: 13px

    +media-breakpoint-up(xxl)
      font-size: 15px

    &--switched
      color: $white

.menu-switcher
  width: 24px
  height: 24px
  transition-duration: 0.5s
  cursor: pointer
  position: relative
  z-index: 900

  +media-breakpoint-up(md)
    display: none

  &__icon
    transition-duration: 0.5s
    position: absolute
    height: 0
    width: 24px
    top: 24px
    background-color: $white

    &:before,
    &:after
      position: absolute
      width: 24px
      height: 1px
      transition-duration: 0.5s
      content: ""
      background-color: $white

    &:before
      top: -16px

    &:after
      top: -9px

  &--switched
    .menu-switcher__icon
      transition-duration: 0.5s

      &:before
        transform: rotateZ(135deg) translate(2px, -2px)

      &:after
        transform: rotateZ(-135deg) translate(2px, 3px)
