// Looking smiley
.looking-smiley
  position: absolute
  transform-origin: 50% 50%
  transform: rotatey(0deg) rotatex(0deg)
  bottom: -50px
  left: 0
  perspective: 85px

  +media-breakpoint-up(md)
    left: 55px

  +media-breakpoint-up(lg)
    bottom: 70px

  +media-breakpoint-up(xxl)
    bottom: 120px
    left: 76px

  &__eye,
  &__mouth
    transform-origin: 50% 50%

  &__icon
    width: 48px
    height: 48px

    +media-breakpoint-up(sm)
      width: 56px
      height: 56px

    +media-breakpoint-up(md)
      width: 64px
      height: 64px

    +media-breakpoint-up(xl)
      width: 72px
      height: 72px

    +media-breakpoint-up(xxl)
      width: 96px
      height: 96px
