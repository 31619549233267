// Preloader

.preloader
  background: $blue
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: 1000
  display: flex
  justify-content: center
  flex-direction: column
  opacity: 1
  transition: opacity 1000ms ease-in-out

  &--show
    opacity: 1

  &--hide
    opacity: 0

  &--none
    display: none

  &__wrapper
    width: 100%
    height: 100%

@keyframes roll
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

@keyframes move
  0%
    transform: translateX(-100px)
  100%
    transform: translateX(100vw)

.rolling-smiley
  position: absolute
  transform-origin: 50% 50%
  will-change: transform
  // animation: move 6400ms cubic-bezier(.8, 0, .2, 1) infinite
  width: 48px
  height: 48px

  &__line
    height: 3px
    background-color: $black
    width: 10vw
    position: absolute
    top: 50%
    right: 20px
    transform: translateY(-50%)
    z-index: 900

  &__icon
    position: relative
    transform-origin: 50% 50%
    will-change: transform
    z-index: 1000
    // animation: 1000ms linear 0ms 1 roll, 800ms linear 1000ms 1 roll, 600ms linear 1800ms 1 roll, 400ms linear 2400ms 4 roll, 600ms linear 4000ms 1 roll, 800ms linear 4600ms 1 roll, 1000ms linear 5400ms 1 roll

  +media-breakpoint-up(sm)
    width: 56px
    height: 56px

  +media-breakpoint-up(md)
    width: 64px
    height: 64px

  +media-breakpoint-up(xl)
    width: 72px
    height: 72px

  +media-breakpoint-up(xxl)
    width: 96px
    height: 96px

