@mixin media-breakpoint-up($name)
  @media (min-width: map-get($breakpoints, $name))
    @content

@mixin media-breakpoint-down($name)
  @media (max-width: map-get($breakpoints, $name)-1px)
    @content

@mixin container-width($name)
  +media-breakpoint-up($name)
    max-width: map-get($container-max-widths, $name)
    @content

@mixin make-container()
  width: 100%
  margin-left: auto
  margin-right: auto
