@import "common/functions"
@import "common/variables"
@import "common/mixins"
@import "common/reset"
@import "common/fonts"
@import "preloader"
@import "header"
@import "looking-smiley"
@import "main"
@import "footer"

.app
  height: 100%
  min-height: 100%
  width: 100%
  position: relative

  display: flex
  flex-direction: column

  overflow-x: hidden

