// Footer
.footer
  position: relative
  width: 100vw
  margin: 0 auto

  display: flex
  flex-direction: column
  justify-content: center

  $root: &

  +container-width(lg)
  +container-width(xl)
  +container-width(xxl)

  &__row
    width: 100%
    display: flex
    justify-content: space-between
    flex-wrap: wrap

    &--bordered
      $bordered-row-color: $gray
      border-top: 1px solid $bordered-row-color
      border-left: 1px solid $bordered-row-color
      border-right: 1px solid $bordered-row-color

      #{$root}__cell

        +media-breakpoint-down(md)

          &:nth-child(2n+1)
            border-right: 1px solid $bordered-row-color

          &:nth-child(-n+2)
            border-bottom: 1px solid $bordered-row-color

        +media-breakpoint-up(md)
          border-right: 1px solid $bordered-row-color

          &:last-child
            border-right: 0

  &__cell
    position: relative
    display: flex
    flex: 0 0 50%
    height: 46px
    background: $black
    font-size: 14px
    font-family: $font-family-sans-serif-regular

    +media-breakpoint-up(md)
      flex: 0 0 25%

    +media-breakpoint-up(xl)
      font-size: 16px
      height: 70px

    +media-breakpoint-up(xxl)
      font-size: 18px
      height: 86px

    &--full-width
      flex: 0 0 100%
      height: 72px
      font-size: 12px
      line-height: 100%
      letter-spacing: 6.5px
      text-transform: uppercase
      font-family: $font-family-sans-serif-medium

      +media-breakpoint-up(xl)
        height: 96px
        font-size: 14px

      +media-breakpoint-up(xxl)
        height: 128px
        font-size: 16px

  &__link
    background: $black
    transition: all 500ms ease
    display: flex
    width: 100%
    justify-content: center
    align-items: center
    color: $white
    text-decoration: none
    $root: &

    &--hover-anim-bg
      transition: all 600ms ease-out
      &:hover
        background: $white

        #{$root}-text
          color: $blue
        #{$root}-arrow
          background: $blue
          width: 24px
          animation: none
          &:before,
          &:after
            background: $blue

    &--hover-anim-text

      #{$root}-text
        animation: link-text-animation-out 500ms ease-out forwards

      &:hover
        #{$root}-text
          animation: link-text-animation-in 500ms ease-out forwards

    &-text
      display: block
      color: $white

    &-arrow
      display: block
      background: $white
      height: 1px
      width: 9px
      margin-left: 8px
      bottom: 1px
      position: relative
      cursor: pointer
      transition: width 500ms ease

      &:before,
      &:after
        content: ""
        background: $white
        position: absolute
        right: -1px
        height: 1px
        width: 4px

      &:before
        bottom: -1px
        transform: rotate(-45deg)

      &:after
        top: -1px
        transform: rotate(45deg)

      &--anim-position
        transform-origin: 50% 50%
        animation: right-arrow-animation 0.6s infinite alternate ease-in-out

@keyframes link-text-animation-in
  0%
    letter-spacing: inherit
  30%
    letter-spacing: 3px
  100%
    letter-spacing: 6px

@keyframes link-text-animation-out
  0%
    letter-spacing: 6px
  30%
    letter-spacing: 3px
  100%
    letter-spacing: inherit

@keyframes right-arrow-animation
  0%
    transform: translateX(0px)
  100%
    transform: translateX(-6px)
