// Variables

// Color system
$white: #fff !default
$black: #000 !default
$gray: #4c4c4c !default
$blue: #0052fb !default
$yellow: #f2c94c !default

$colors: ("blue": $blue, "yellow": $yellow, "white": $white, "gray": $gray) !default



// Theme variables
$body-bg: $blue
$body-color: $white

// Grid breakpoints
$breakpoint-xs: 320px
$breakpoint-sm: 390px
$breakpoint-md: 768px
$breakpoint-lg: 1280px
$breakpoint-xl: 1440px
$breakpoint-xxl: 1920px

$breakpoints: (xs: $breakpoint-xs, sm: $breakpoint-sm, md: $breakpoint-md, lg: $breakpoint-lg, xl: $breakpoint-xl, xxl: $breakpoint-xxl) !default

// Container max widths

$container-xs: 288px
$container-sm: 342px
$container-md: 672px
$container-lg: 1184px
$container-xl: 1344px
$container-xxl: 1632px

$container-max-widths: (xs: $container-xs, sm: $container-sm, md: $container-md, lg: $container-lg, xl: $container-xl, xxl: $container-xxl) !default

// Typography
// Effects the value of `rem`, which is used for as well font sizes, paddings and margins
$font-size-root: 1em !default // Assumes the browser default, typically `16px`
$font-weight-base: 400 !default
$line-height-base: 1.5 !default

$font-family-sans-serif-book: "Stolzl-Book", system-ui, -apple-system, "Helvetica Neue", Arial, sans-serif !default
$font-family-sans-serif-medium: "Stolzl-Medium", system-ui, -apple-system, "Helvetica Neue", Arial, sans-serif !default
$font-family-sans-serif-regular: "Stolzl-Regular", system-ui, -apple-system, "Helvetica Neue", Arial, sans-serif !default
