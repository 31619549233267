// Main

.main
  position: relative
  width: 100%
  margin: 0 auto
  flex: 1

  +container-width(xs)
  +container-width(sm)
  +container-width(md)
  +container-width(lg)
  +container-width(xl)
  +container-width(xxl)

.sections
  display: flex
  height: 100%
  flex-direction: column
  justify-content: center

  +media-breakpoint-up(sm)
    justify-content: flex-end

  +media-breakpoint-up(lg)
    flex-direction: row
    justify-content: center
    align-items: center

  &__item
    position: relative
    flex: 0 1
    height: 100%

    &--primary
      order: 1
      flex-basis: 65%

      +media-breakpoint-up(sm)
        flex-basis: 50%

      +media-breakpoint-up(md)
        flex-basis: 60%

        @media (orientation: landscape)
          flex-basis: 80%

      +media-breakpoint-up(lg)
        flex-basis: 43%


    &--secondary
      order: 2
      width: 75%
      align-self: flex-end
      flex-basis: 35%

      +media-breakpoint-up(md)
        width: 60%
        flex-basis: 30%

        @media (orientation: landscape)
          flex-basis: 20%

      +media-breakpoint-up(lg)
        flex-basis: 33%
        width: 100%
        height: 79.6%

      +media-breakpoint-up(xl)
        flex-basis: 32%

      +media-breakpoint-up(xxl)
        flex-basis: 28%
        height: 76.5%

.tagline
  font-family: $font-family-sans-serif-regular
  font-size: 40px
  letter-spacing: -1px
  line-height: 1
  color: $white

  position: absolute
  width: 200%
  top: 50%
  transform: translateY(-50%)

  .char
    display: inline-block

  +media-breakpoint-up(sm)
    font-size: 50px

  +media-breakpoint-up(md)
    font-size: 96px

  +media-breakpoint-up(xl)
    font-size: 112px

  +media-breakpoint-up(xxl)
    font-size: 128px

  &__row
    position: relative

    &--first

    &--second
      padding-left: 62px

      +media-breakpoint-up(sm)
        padding-left: 80px

      +media-breakpoint-up(md)
        padding-left: 155px

      +media-breakpoint-up(xxl)
        padding-left: 205px

    &--third

    &--fourth
      padding-left: 62px

      +media-breakpoint-up(sm)
        padding-left: 80px

      +media-breakpoint-up(md)
        padding-left: 155px

      +media-breakpoint-up(lg)
        padding-left: 335px

      +media-breakpoint-up(xxl)
        padding-left: 450px


.lead
  position: relative
  font-family: $font-family-sans-serif-book
  font-size: 13px
  letter-spacing: 1px
  line-height: 23px
  color: $white
  text-transform: uppercase

  .char
    display: inline-block

  br
    display: none

  @at-root
    html[lang="uk-UA"]
      .lead
        +media-breakpoint-up(lg)
          left: 30%

        +media-breakpoint-up(lg)
          br:nth-child(1),
          br:nth-child(2)
            display: inherit
